/* eslint-disable react/prop-types */

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import React, { ReactElement } from 'react';

import { Redirect, Route } from "react-router-dom";
import { selectUser } from '../features/user/userSlice';
import { useAppSelector } from './hooks'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function ProtectedRoute({ component: Component, ...restOfProps }) : ReactElement {
  const user = useAppSelector(selectUser) ;
  const isAuthenticated = user && user.token && user.token.length;

  return (
    <Route exact
      {...restOfProps}
      render={(props) =>
        isAuthenticated ? <Component {...props} {...restOfProps} /> : <Redirect to="/login" />
      }
    />
  );
}

export default ProtectedRoute;