
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import React, { ReactElement, useEffect } from 'react';
import { Dialog, List, Loading, Toast } from 'antd-mobile-alpha';
import { selectDevices, selectEditedDevice, updateEditedDevice } from '../features/devices/devicesSlice';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { useHistory } from 'react-router';
import { editShowBackButton, editTitle } from '../features/navbar/navSlice';
import { BorderOutlined, CheckSquareOutlined, EditOutlined } from '@ant-design/icons';
import axios from 'axios';
import { API_URL } from '../constants';
import { selectCurrentAgent, updateAgents, updateCurrentAgent } from '../features/application/applicationSlice';
import { selectUser } from '../features/user/userSlice';

type DeviceListProps = {
  agent: Agent | null,
  loadDevices: () => void,
  loading: boolean
}

export default function DeviceList(props: DeviceListProps): ReactElement {
  const dispatch = useAppDispatch()
  useEffect(() => {
    dispatch(editTitle('Aparate'))
    dispatch(editShowBackButton(false))
  })

  const history = useHistory();
  const devices = useAppSelector(selectDevices)
  const unsavedEditedDevice = useAppSelector(selectEditedDevice);
  const currentAgent = useAppSelector(selectCurrentAgent);
  const user = useAppSelector(selectUser);
  let idAparat: string | number;
  let locatie: string | number;
  if (unsavedEditedDevice) {
    unsavedEditedDevice.details.forEach(item => {
      if (item.key === 'idAparat') {
        idAparat = item.value as string | number;
      } else if (item.key === 'locatie') {
        locatie = item.value as string;
      }
    })
  }

  useEffect(() => {
    props.loadDevices();
    loadAgents();
  }, []);

  function loadAgents() {
    axios.get(`${API_URL}/api/agents`)
      .then(response => {
        dispatch(updateAgents(response.data));
        if (!currentAgent) {
          dispatch(updateCurrentAgent({ name: user.name, id: user.id.toString() }));
        }
      })
      .catch(error => {
        let message = 'Unknown error';
        if (error.response && error.response.data && error.response.data.message) {
          message = error.response.data.message
        }
        Toast.show({ content: message, position: 'top', maskClickable: true });
      })
  }


  function onDeviceClick(item: Device) {
    if (idAparat && idAparat !== item.idAparat) {
      Dialog.confirm({
        title: 'Modificari nesalvate',
        content: `Esti sigur ca vrei sa abandonezi modificarile facute ${locatie ? ` pe aparatul ${locatie}` : ''}? Continuarea va sterge modificarile nesalvate.`,
        confirmText: 'Continua',
        cancelText: 'Inapoi',
        onConfirm: async () => {
          dispatch(updateEditedDevice(null))
          dispatch(editTitle(item.locatie))
          history.push(`/device/${item.idAparat}`);
        },
      })
    } else {
      dispatch(editTitle(item.locatie))
      history.push(`/device/${item.idAparat}`);
    }
  }

  function getPrefix(item: Device) {
    if (idAparat && idAparat === item.idAparat) {
      return <EditOutlined />
    } else if (item.vizitat) {
      return <CheckSquareOutlined />
    }
    return <BorderOutlined />
  }

  return (<div>
    {devices && devices.length ?
      < List style={{ marginTop: 1 }}>
        {devices.map(item => {
          return < List.Item className='hide-hover-effect' prefix={getPrefix(item)} key={item.idAparat} onClick={() => onDeviceClick(item)} description={item.matricola} extra={item.data}>
            {item.locatie}
          </ List.Item >
        })}
      </ List > :
      <div style={{ textAlign: 'center', paddingTop: 16 }}>
        {props.loading ? <Loading color="primary" /> : 'Nu au fost gasite aparate'}
      </div>
    }
  </div>);
}
