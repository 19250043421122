import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../../app/store'

const initialState: DeviceState = {
  list: [],
  editedDevice: null,
  visit: null
}

export const devicesSlice = createSlice({
  name: 'devices',
  initialState,
  reducers: {
    updateDevices: (state, action: PayloadAction<Device[]>) => {
      state.list = action.payload
    },
    updateEditedDevice: (state, action: PayloadAction<EditedDevice | null>) => {
      state.editedDevice = action.payload ? JSON.parse(JSON.stringify(action.payload)) : null
    },
    updateVisit: (state, action: PayloadAction<Visit | null>) => {
      state.visit = action.payload
    },
  }
})

export const { updateDevices, updateEditedDevice, updateVisit } = devicesSlice.actions

export const selectDevices = (state: RootState): Device[] => state.devices.list
export const selectEditedDevice = (state: RootState): EditedDevice | null => state.devices.editedDevice
export const selectVisit = (state: RootState): Visit | null => state.devices.visit

export default devicesSlice.reducer