import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../../app/store'

// Define a type for the slice state
interface UserState {
  id: number
  name: string
  code: string
  token: string
}

// Define the initial state using that type
const initialState: UserState = {
  id: -1,
  name: '',
  code: '',
  token: ''
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    editUser: (state, action: PayloadAction<UserState>) => {
      state.id = action.payload.id;
      state.name = action.payload.name;
      state.code = action.payload.code;
      state.token = action.payload.token;
    }
  }
})

export const { editUser } = userSlice.actions

export const selectUser = (state: RootState) : UserState => state.user

export default userSlice.reducer