import { configureStore } from '@reduxjs/toolkit'
import userReducer from '../features/user/userSlice'
import devicesReducer from '../features/devices/devicesSlice'
import navbarReducer from '../features/navbar/navSlice'
import applicationReducer from '../features/application/applicationSlice'
import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { combineReducers } from 'redux'

const appReducer = combineReducers({ user: userReducer, devices: devicesReducer, navbar: navbarReducer, application: applicationReducer });
const persistConfig = {
  key: 'app',
  storage,
}

const persistedReducer = persistReducer(persistConfig, appReducer)

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch