// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import React, { ReactElement } from 'react';
import { Table } from 'antd';
import { useWindowDimensions } from '../../app/hooks';
import { Dialog, Input } from 'antd-mobile-alpha';

type SelectionsTabProps = {
  headers: DeviceHeader[]
  selections: [],
  updateSelections: (selections: []) => void,
  indexUpdated: () => void
}

const handleFocus = (e) => {
  e.target.select();
};

const handleKeyPress = (e) => {
  if ((e.code === 'Enter' ||  e.key === 'Enter' || e.which == 13 || e.keyCode == 13) && e.target && e.target.id) {
    const results = e.target.id.split('-');
    if (results && results.length === 2) {
      const newId = `${Number(results[0]) + 1}-${results[1]}`;
      const nextInput = document.getElementById(newId);
      if (nextInput) {
        nextInput.focus();
      }
    }

  }
}

export default function SelectionsTab({ headers, selections, updateSelections, indexUpdated }: SelectionsTabProps): ReactElement {
  let index = 0;
  const dataSource = JSON.parse(JSON.stringify(selections)).map(item => {
    item.key = index++;
    return item;
  })

  const headersWithRender = JSON.parse(JSON.stringify(headers)).map(item => {
    if (!item.readOnly) {
      // eslint-disable-next-line
      item.render = (text: number | string, { }, index: number) => (
        <Input
          id={index.toString() + '-' + item.key}
          enterKeyHint=''
          value={text}
          onChange={(value) => editSelections(item.key, value, index)}
          inputMode='decimal'
          onBlur={(event) => onBlur(event, item.key, index)}
          pattern="\d*"
          onFocus={handleFocus}
          onKeyPress={handleKeyPress}
          className={item.key === 'necesar' || item.key === 'indexCurent' ? 'green-background' : (item.key === 'expirate' ? 'red-background' : '')}
          onPaste={(e) => {
            e.preventDefault()
            return false;
          }}
          onCopy={(e) => {
            e.preventDefault()
            return false;
          }}
          onCut={(e) => {
            e.preventDefault()
            return false;
          }}
          onDrag={(e) => {
            e.preventDefault()
            return false;
          }}
          onDrop={(e) => {
            e.preventDefault()
            return false;
          }}
          onSelect={(e) => {
            e.preventDefault()
            return false;
          }}
          onSelectCapture={(e) => {
            e.preventDefault()
            return false;
          }}
        />
      )
    } else {
      // eslint-disable-next-line
      item.render = (text: number | string, record) => {
        return (
          <div className={item.key === 'necesar' || item.key === 'indexCurent' ? 'green-background' : (item.key === 'expirate' ? 'red-background' : '')}
          >
            {text}
            {item.key === 'produs' ? (
              <div style={{ opacity: 0.5 }}>{record['pret']} lei</div>
            ) : ''}
          </div>
        )
      }
    }
    return item;
  })

  const { width } = useWindowDimensions();

  const editSelections = (key: string, value: number | string | boolean, index: number): void => {
    const selectionsCopy = JSON.parse(JSON.stringify(selections));
    selectionsCopy[index][key] = value;
    updateSelections(selectionsCopy);
  }

  const onBlur = (event, key: string, index: number): void => {
    const selectionsCopy = JSON.parse(JSON.stringify(selections));
    event.preventDefault();
    if (key === 'necesar') {
      if (!selectionsCopy[index]['incarcat']) {
        selectionsCopy[index]['incarcat'] = selectionsCopy[index]['necesar'];
      }
      if (Number(selectionsCopy[index]['necesar']) > Number(selectionsCopy[index]['capMaxima'])) {
        alert('Necesar nu poate fi mai mare decat capacitatea!', event.currentTarget);
      }
    }
    updateSelections(selectionsCopy);
    if (key === 'indexCurent') {
      if (Number(selectionsCopy[index]['indexCurent']) < Number(selectionsCopy[index]['indexVechi'])) {
        alert('Indexul curent nu poate fi mai mic decat indexul vechi!', event.currentTarget);
      }
      indexUpdated();
    }

    if (key === 'incarcat' || key === 'expirate') {
      if (Number(selectionsCopy[index]['incarcat']) > Number(selectionsCopy[index]['capMaxima'])) {
        alert('Incarcat nu poate fi mai mare decat capacitatea!', event.currentTarget);
      }
      if (Number(selectionsCopy[index]['incarcat']) > Number(selectionsCopy[index]['necesar'])) {
        alert('Incarcat nu poate fi mai mare decat necesar!', event.currentTarget);
      }
      if (Number(selectionsCopy[index]['expirate']) > Number(selectionsCopy[index]['necesar'])) {
        alert('Expirate nu poate fi mai mare decat necesar!', event.currentTarget);
      }
    }
  }

  const alert = (content: string, target, title = 'Eroare validare'): void => {
    Dialog.alert({
      title,
      content,
      confirmText: 'Modifica',
      closeOnMaskClick: true,
      onConfirm: () => {
        target.focus()
      }
    })
  }

  return (
    <Table className="antTableTd" columns={headersWithRender} dataSource={dataSource} pagination={{ pageSize: 1000000, style: { 'display': 'none' } }} scroll={{ x: width }} />
  )
}
