// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import React, { ReactElement, useEffect, useState } from 'react';
import { Button, List, Loading, Picker, Toast } from 'antd-mobile-alpha';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { editShowBackButton, editTitle } from '../features/navbar/navSlice';
import { selectCurrentAgent } from '../features/application/applicationSlice';
import axios from 'axios';
import { API_URL } from '../constants';
import { SearchBar, Tabs } from 'antd-mobile';
import { CloseOutlined } from '@ant-design/icons';

type Product = {
  IdProdus: string | number;
  Denumire: string;
  UM: string;
  Cod: string;
  Ambalat: string;
  Cantitate?: number;
};

export default function Order(): ReactElement {
  const currentAgent = useAppSelector(selectCurrentAgent);
  const dispatch = useAppDispatch();

  const [loadingProducts, setLoadingProducts] = useState(true);
  const [sendingOrder, setSendingOrder] = useState(false);
  const [products, setProducts] = useState<Product[]>([]);
  const [searchText, setSearchText] = useState('');
  const [pickerVisibleId, setPickerVisibleId] = useState<null | number | string>(null);

  useEffect(() => {
    dispatch(editTitle('Comandă'));
    dispatch(editShowBackButton(true));
    if (!products?.length) loadProducts();
  }, []);

  const loadProducts = () => {
    const query = `${currentAgent ? '?agent=' + currentAgent?.id : ''}`;
    axios
      .get(`${API_URL}/api/products${query}`)
      .then((response) => {
        setProducts([]);
        setTimeout(() => {
          if (!products?.length) {
            setProducts(
              response.data.map((product) => {
                return { ...product, Cantitate: 0 };
              }) ?? [],
            );
          }
        });
      })
      .catch((error) => {
        let message = 'Unknown error';
        if (error.response && error.response.data && error.response.data.message) {
          message = error.response.data.message;
        }
        Toast.show({ content: message, position: 'top', maskClickable: true });
      })
      .finally(() => {
        setLoadingProducts(false);
      });
  };

  const selectedProducts = products.filter((product) => product.Cantitate && product.Cantitate > 0);

  const sendOrder = () => {
    setSendingOrder(true);
    const query = `${currentAgent ? '?agent=' + currentAgent?.id : ''}`;
    axios
      .post(`${API_URL}/api/orders${query}`, { products: selectedProducts })
      .then((response) => {
        if (response?.data?.message) {
          Toast.fail({ content: response.data.message, position: 'center', maskClickable: true, duration: 5000 });
          setSendingOrder(false);
        } else {
          Toast.success({ content: 'Comandă trimisă', position: 'center', maskClickable: true });
          setTimeout(() => {
            setSendingOrder(false);
            window.history.back();
          }, 500);
        }
      })
      .catch((error) => {
        let message = 'Unknown error';
        if (error.response && error.response.data && error.response.data.message) {
          message = error.response.data.message;
          console.log(message);
        }
        Toast.fail({ content: message, position: 'center', maskClickable: true, duration: 5000 });
        setSendingOrder(false);
      });
  };

  const getQuantity = (product: Product) => {
    if (product.Ambalat && product.Ambalat !== '.00') {
      return parseFloat(product.Ambalat) ?? 1;
    }
    return 1;
  };

  const getQuantityOptions = () => {
    const product = products.find((prod) => prod.IdProdus === pickerVisibleId);
    if (!product) return [];
    const minQuantity = getQuantity(product);
    const list: { label: string; value: string }[] = [
      {
        label: '0',
        value: '0',
      },
    ];
    for (let i = 1; i < (minQuantity === 1 ? 101 : 50); i++) {
      const value = `${minQuantity * i}`;
      list.push({
        label: value,
        value,
      });
    }
    return list;
  };

  const onRemoveProduct = (product) => {
    setProducts(
      products.map((p) => {
        return { ...p, ...(p.IdProdus === product.IdProdus && { Cantitate: 0 }) };
      }),
    );
  };

  const onChangeQuantity = (quantity) => {
    const product = products.find((prod) => prod.IdProdus === pickerVisibleId);
    if (!product) return;
    const minQuantity = getQuantity(product);
    setProducts(
      products.map((selected) => {
        if (selected.IdProdus === product.IdProdus) {
          if (quantity % minQuantity !== 0) {
            if (quantity < minQuantity || (product.Cantitate && quantity < product.Cantitate)) {
              if (!product.Cantitate) product.Cantitate = minQuantity;
              quantity = product.Cantitate - minQuantity > 0 ? product.Cantitate - minQuantity : minQuantity;
            } else {
              const timesGreater = Math.floor(quantity / minQuantity);
              quantity = minQuantity * timesGreater + minQuantity;
            }
          }
          selected.Cantitate = quantity ?? getQuantity(product);
        }
        return selected;
      }),
    );
  };

  const tabs = [
    { title: 'Produse' },
    { title: `Coș${selectedProducts.length ? ' (' + selectedProducts.length + ')' : ''}` },
  ];

  return loadingProducts ? (
    <div style={{ textAlign: 'center', paddingTop: 18 }}>
      <Loading color="primary" />
    </div>
  ) : (
    <div className="order-container">
      <Tabs tabs={tabs} initialPage={0} useOnPan={false} swipeable={false}>
        <div style={{ height: 'calc(100vh - 100px)', paddingTop: '50px' }}>
            <SearchBar
              className="product-search"
              placeholder="Caută produs"
              onChange={(val) => {
                setSearchText(val ?? '');
              }}
              style={{ position: 'fixed', top: 0, left: 0, width: '100%'}}
            />
            <List style={{ height: '100%', overflow: 'auto' }}>
              {products
                .filter((product) => {
                  return (
                    searchText.length === 0 ||
                    product.Denumire.toLowerCase().includes(searchText.toLowerCase()) ||
                    product.Cod.toLowerCase().includes(searchText.toLowerCase())
                  );
                })
                .map((product) => {
                  return (
                    <List.Item
                      clickable={false}
                      style={{ backgroundColor: '#e8f6fa' }}
                      onClick={() => (sendingOrder ? null : setPickerVisibleId(product.IdProdus))}
                      key={product.IdProdus}
                      description={`${product.Cod} | ${product.Ambalat}`}
                      extra={
                        <div>
                          <Button
                            style={{ width: 48, paddingLeft: 4, paddingRight: 4 }}
                            disabled={sendingOrder}
                            onClick={(event) => {
                              event.preventDefault();
                              event.stopPropagation();
                              setPickerVisibleId(product.IdProdus);
                            }}
                          >
                            {product.Cantitate}
                          </Button>
                          <span
                            style={{
                              marginRight: 2,
                              marginLeft: 4,
                              fontSize: 14,
                              minWidth: 36,
                              display: 'inline-block',
                              textAlign: 'center',
                            }}
                          >
                            {product.UM ? product.UM.trim() : ''}
                          </span>
                        </div>
                      }
                    >
                      {product.Denumire}
                    </List.Item>
                  );
                })}
            </List>
        </div>
        <div style={{ height: '100%' }}>
          {selectedProducts?.length ? (
            <>
              <List style={{ height: 'calc(100% - 52px)', overflow: 'auto' }}>
                {selectedProducts.map((product) => {
                  return (
                    <List.Item
                      clickable={false}
                      key={product.IdProdus}
                      style={{ backgroundColor: '#e8f6fa' }}
                      description={`${product.Cod} | ${product.Ambalat}`}
                      extra={
                        <div>
                          <Button
                            style={{ width: 48, paddingLeft: 4, paddingRight: 4 }}
                            disabled={sendingOrder}
                            onClick={(event) => {
                              event.preventDefault();
                              event.stopPropagation();
                              setPickerVisibleId(product.IdProdus);
                            }}
                          >
                            {product.Cantitate}
                          </Button>
                          <span
                            style={{
                              marginRight: 2,
                              marginLeft: 4,
                              fontSize: 14,
                              minWidth: 36,
                              display: 'inline-block',
                              textAlign: 'center',
                            }}
                          >
                            {product.UM ? product.UM.trim() : ''}
                          </span>

                          <Button disabled={sendingOrder} onClick={() => onRemoveProduct(product)}>
                            <CloseOutlined />
                          </Button>
                        </div>
                      }
                    >
                      {product.Denumire}
                    </List.Item>
                  );
                })}
              </List>
              <Button
                style={{ width: 'calc(100% - 16px)', margin: 8 }}
                color="primary"
                loading={sendingOrder}
                onClick={sendOrder}
              >
                Trimite comanda
              </Button>
            </>
          ) : (
            <h3 style={{ textAlign: 'center', paddingTop: 24, opacity: 0.7 }}>Nici un produs selectat</h3>
          )}
          <Picker.Cascader
            options={getQuantityOptions()}
            visible={!!pickerVisibleId}
            onClose={() => {
              setPickerVisibleId(null);
            }}
            confirmText="Selecteaza"
            cancelText="Anuleaza"
            onConfirm={(value) => (value && value[0] ? onChangeQuantity(value[0]) : null)}
          />
        </div>
      </Tabs>
    </div>
  );
}
