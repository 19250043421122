// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import React, { ReactElement } from 'react';
import { Button, Checkbox, Dialog, Empty, Grid, List } from 'antd-mobile-alpha';
import { UnorderedListOutlined } from '@ant-design/icons';
import { useAppSelector } from '../app/hooks';
import { selectEditedDevice } from '../features/devices/devicesSlice';

export default function RequiredProductsDialog(): ReactElement {
  const editedDevice = useAppSelector(selectEditedDevice)
  if (!editedDevice) {
    return <template />
  }
  const selections = JSON.parse(JSON.stringify(editedDevice.selections))
  let list: Record<string, unknown> = {}
  selections.forEach(selection => {
    if (!list) {
      list = {}
    }
    if (list[selection.produs]) {
      list[selection.produs] = parseInt(list[selection.produs] as string) + parseInt(`${selection.necesar}`)
    } else {
      (list[selection.produs] as number) = selection.necesar
    }
  })

  const hasRequired = Object.keys(list).filter(produs => parseInt(`${list[produs]}`)).length
  return < Button style={{ right: -10 }}
    onClick={() =>
      Dialog.alert({
        confirmText: 'Inchide',
        bodyClassName: 'required-products-dialog-body',
        title: 'Necesar',
        content: (
          hasRequired ? < List style={{ width: '100%' }}>
            {Object.keys(list).map((produs: string) => {
              if (!list[produs] || list[produs] === '0') {
                return ''
              }
              return < List.Item key={produs}>
                < Checkbox style={{ width: '100%' }}>
                  < Grid columns={6} >
                    < Grid.Item span={5}>
                      {produs}
                    </ Grid.Item >
                    < Grid.Item style={{ textAlign: 'end', alignSelf: 'center' }}>
                      {list[produs] as number}
                    </ Grid.Item >
                  </ Grid >
                </ Checkbox >
              </ List.Item >
            })}
          </ List > :
            <div style={{ textAlign: 'center' }}>
              <br />
              < Empty description="Nu exista date" />
              <br /><br />
            </div>
        )
      })
    }
  >
    <UnorderedListOutlined />
  </ Button >
}
