import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../../app/store'

const initialState: ApplicationState = {
  agents: [],
  currentAgent: null
}

export const applicationSlice = createSlice({
  name: 'application',
  initialState,
  reducers: {
    updateAgents: (state, action: PayloadAction<Agent[]>) => {
      state.agents = action.payload
    },
    updateCurrentAgent: (state, action: PayloadAction<Agent | null>) => {
      state.currentAgent = action.payload ? JSON.parse(JSON.stringify(action.payload)) : null
    },
  }
})

export const { updateAgents, updateCurrentAgent } = applicationSlice.actions

export const selectAgents = (state: RootState): Agent[] => state.application.agents
export const selectCurrentAgent = (state: RootState): Agent | null => state.application.currentAgent

export default applicationSlice.reducer