// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import React, { ReactElement, useEffect, useState } from 'react';
import './App.scss';
import { Route, BrowserRouter as Router, Switch, Redirect } from 'react-router-dom';
import { Login } from './components/Login';
import DeviceList from './components/DeviceList';
import DeviceLayout from './components/DeviceLayout';
import ProtectedRoute from './app/ProtectedRoutes';
import { useAppDispatch, useAppSelector, useWindowDimensions } from './app/hooks';
import { selectShowBackButton, selectTitle } from './features/navbar/navSlice';
import { Icon, NavBar } from 'antd-mobile';
import { selectEditedDevice, updateDevices, updateEditedDevice } from './features/devices/devicesSlice';
import { Button, Dialog, Picker, Toast, Popover } from 'antd-mobile-alpha';
import { FileAddOutlined, LogoutOutlined, MoreOutlined } from '@ant-design/icons';
import axios from 'axios';
import { editUser, selectUser } from './features/user/userSlice';
import { API_URL } from './constants';
import RequiredProductsDialog from './components/RequiredProductsDialog';
import {
  selectAgents,
  selectCurrentAgent,
  updateAgents,
  updateCurrentAgent,
} from './features/application/applicationSlice';
import { Action } from 'antd-mobile-alpha/es/components/popover';
import Order from './components/Order';
function App(): ReactElement {
  const dispatch = useAppDispatch();
  const title = useAppSelector(selectTitle);
  const user = useAppSelector(selectUser);
  const showBackButton = useAppSelector(selectShowBackButton);
  const editedDevice = useAppSelector(selectEditedDevice);
  const agents = useAppSelector(selectAgents);
  const agentOptions = agents
    ? agents.map((agent) => {
        return { label: agent.name, value: agent.id };
      })
    : [];
  const currentAgent = useAppSelector(selectCurrentAgent);
  const [loadingDevices, setLoadingDevices] = useState(false);
  const [pickerVisible, setPickerVisible] = useState(false);
  axios.interceptors.request.use(
    (request) => {
      if (user && user.token) {
        request.headers['Authorization'] = `Bearer ${user.token}`;
      }
      return request;
    },
    (error) => {
      return Promise.reject(error);
    },
  );
  axios.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      if (error.response.status === 401) {
        clearDataAndRedirectToLogin();
      }
      return Promise.reject(error);
    },
  );

  let deviceHeight: number | string = '100vh';
  const windowDimensions = useWindowDimensions();
  useEffect(() => {
    deviceHeight = windowDimensions.height;
  });

  const abandonChanges = () => {
    if (editedDevice) {
      Dialog.confirm({
        title: 'Abandon',
        content: 'Esti sigur ca vrei sa abandonezi modificarile facute?',
        confirmText: 'Da',
        cancelText: 'Nu',
        onConfirm: async () => {
          dispatch(updateEditedDevice(null));
          window.history.back();
        },
      });
    } else {
      window.history.back();
    }
  };
  const clearDataAndRedirectToLogin = () => {
    localStorage.removeItem('persist:app');
    dispatch(editUser({ id: -1, name: '', token: '', code: '' }));
    dispatch(updateDevices([]));
    dispatch(updateEditedDevice(null));
    dispatch(updateAgents([]));
    dispatch(updateCurrentAgent(null));
    window.location.reload();
  };

  const logout = () => {
    Dialog.confirm({
      title: 'Deconectare',
      confirmText: 'Continua',
      cancelText: 'Inapoi',
      onConfirm: async () => {
        const token = user.token;
        Toast.loading({ maskClickable: false });
        axios
          .post(`${API_URL}/api/logout`, { token })
          .catch((error) => {
            let message = 'Unknown error';
            if (error.response && error.response.data && error.response.data.message) {
              message = error.response.data.message;
            }
            Toast.show({ content: message, position: 'top', maskClickable: true });
          })
          .finally(() => {
            clearDataAndRedirectToLogin();
          });
      },
    });
  };

  function rightContent() {
    const actions: Action[] = [
      { key: 'order', icon: <FileAddOutlined />, text: 'Comandă' },
      { key: 'logout', icon: <LogoutOutlined />, text: 'Deconectare' },
    ];
    if (title === 'Aparate') {
      return (
        <Popover.Menu
          mode="dark"
          actions={actions}
          placement="bottom"
          onSelect={(node) => {
            console.log(node);
            if (node.key === 'logout') {
              logout();
            } else if (node.key === 'order') {
              window.location.pathname = '/order';
            }
          }}
        >
          <Button style={{ right: -10 }}>
            <MoreOutlined />
          </Button>
        </Popover.Menu>
      );
    } else if (editedDevice && editedDevice.details) {
      let isSnaky = false;
      editedDevice.details.forEach((detail) => {
        if (detail.key === 'model' && (detail.value as string).includes('(S)')) {
          isSnaky = true;
        }
      });
      return isSnaky ? <RequiredProductsDialog /> : '';
    }
    return '';
  }

  function leftContent() {
    return window.location.pathname === '/devices' && user && user.id > 0 && currentAgent && !showBackButton ? (
      <div>
        <Button
          loading={loadingDevices}
          fill="outline"
          style={{ left: -10 }}
          onClick={() => {
            setPickerVisible(true);
          }}
        >
          {currentAgent.name}
        </Button>
        <Picker.Cascader
          options={agentOptions}
          visible={pickerVisible}
          onClose={() => {
            setPickerVisible(false);
          }}
          confirmText="Selecteaza"
          cancelText="Anuleaza"
          onConfirm={(value) => updateAgent(value)}
        />
      </div>
    ) : (
      ''
    );
  }

  function updateAgent(value) {
    const newAgent = agents.filter((agent) => agent.id === value[0])[0];
    dispatch(updateCurrentAgent(newAgent));
    clearAndLoadDevices(newAgent);
  }

  function clearAndLoadDevices(agent: Agent) {
    dispatch(updateDevices([]));
    dispatch(updateEditedDevice(null));
    loadDevices(agent);
  }

  function loadDevices(agent: Agent | null = null) {
    setLoadingDevices(true);
    let query = `${currentAgent ? '?agent=' + currentAgent.id : ''}`;
    if (agent) {
      query = `${agent ? '?agent=' + agent.id : ''}`;
    }
    axios
      .get(`${API_URL}/api/devices${query}`)
      .then((response) => {
        dispatch(updateDevices(response.data));
      })
      .catch((error) => {
        let message = 'Unknown error';
        if (error.response && error.response.data && error.response.data.message) {
          message = error.response.data.message;
        }
        Toast.show({ content: message, position: 'top', maskClickable: true });
      })
      .finally(() => {
        setLoadingDevices(false);
      });
  }

  return (
    <Router>
      <NavBar
        style={{
          position: 'fixed',
          zIndex: 10,
          width: '100%',
          top: 0,
          padding: 0,
          backgroundColor: 'white',
          borderBottom: '1px solid rgba(0,0,0,.1)',
        }}
        mode="light"
        icon={showBackButton ? <Icon type="left" /> : ''}
        onLeftClick={showBackButton ? abandonChanges : () => null}
        rightContent={rightContent()}
        leftContent={leftContent()}
      >
        {title}
      </NavBar>
      <div style={{ paddingTop: 45, height: deviceHeight }}>
        <Switch>
          <Route path="/login">
            <Login />
          </Route>
          <Route exact path="/">
            <Redirect to="/devices" />
          </Route>
          <ProtectedRoute
            path="/devices"
            component={DeviceList}
            agent={currentAgent}
            loading={loadingDevices}
            loadDevices={loadDevices}
          />
          <ProtectedRoute path="/device/:id" abandonChanges={abandonChanges} component={DeviceLayout} />
          <ProtectedRoute path="/order" component={Order} />
        </Switch>
      </div>
    </Router>
  );
}

export default App;
