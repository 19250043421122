// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import React, { ReactElement, useState } from 'react';
import { List, Input, Checkbox, DatePicker, Dialog, } from 'antd-mobile-alpha';
import { CheckboxValue } from 'antd-mobile-alpha/es/components/checkbox';

type DeviceTabProps = {
  details: DeviceDetail[],
  updateDetails: (details: DeviceDetail[]) => void
}

const now = new Date()

const handleFocus = (e) => {
  e.target.select();
};

export default function DeviceTab(props: DeviceTabProps): ReactElement {
  const [visible, setVisible] = useState(false)
  // const [pickerVisible, setPickerVisible] = useState(false)
  const details = [...props.details]
  const editDetails = (key: string, value: number | string | boolean): void => {
    const detailsCopy = JSON.parse(JSON.stringify(details));
    detailsCopy.forEach((deviceDetails: DeviceDetail) => {
      if (deviceDetails.key === key) {
        deviceDetails.value = value;
        props.updateDetails(detailsCopy);
      }
    })
  }

  const handleBlur = (event, key: string, value: string | number) => {
    if (key === 'indexNou') {
      details.forEach((deviceDetails: DeviceDetail) => {
        if (deviceDetails.key === 'indexVechi' && (Number(deviceDetails.value) > Number(value))) {
          alert('Indexul nou nu poate fi mai mic decat indexul vechi!', event.currentTarget);
        }
      })
    }
  }

  return (<div>
    <List style={{
      '--prefix-width': '6.5em',
    }}>
      {details.map(deviceDetails => {
        if (deviceDetails.key === 'idAparat') {
          return <div key={-1} />
        }
        if (deviceDetails.type === 'date') {
          return <div key={deviceDetails.key}>
            <List.Item key={deviceDetails.key} style={{ display: 'block', opacity: deviceDetails.readOnly ? 0.7 : 1 }} prefix={deviceDetails.label} onClick={() => {
              setVisible(true)
            }} className="list-text-align-end">
              {deviceDetails.value ? deviceDetails.value : ''}

            </List.Item>
            < DatePicker
              confirmText="Selecteaza"
              cancelText="Anuleaza"
              visible={visible}
              onClose={() => {
                setVisible(false)
              }}
              defaultValue={now}
              max={now}
              onConfirm={val => {
                deviceDetails.value = val.toLocaleDateString()
              }}
            />
          </div>
        } else if (deviceDetails.type === 'checkbox') {
          return <List.Item style={{ color: 'black' }} prefix={deviceDetails.label} onClick={() => editDetails(deviceDetails.key, !deviceDetails.value)} arrow={false} key={deviceDetails.key}>
            <div style={{ width: '100%', direction: 'rtl' }}>
              <Checkbox
                value={deviceDetails.value as CheckboxValue | undefined}
                checked={!!deviceDetails.value}
                onChange={() => editDetails(deviceDetails.key, !deviceDetails.value)}
                style={{ opacity: deviceDetails.readOnly ? 0.7 : 1, paddingRight: 4 }}
              />
            </div>
          </List.Item>
        }
        // else if (deviceDetails.type === 'select' && agents && agents.length) {
        //   return <div key={deviceDetails.key} >
        //     <List.Item
        //       className="list-text-align-end"
        //       prefix={deviceDetails.label}
        //       style={{ color: 'black', paddingRight: 6 }}
        //       arrow={true}
        //       onClick={() => {
        //         setPickerVisible(true)
        //       }}>
        //       {deviceDetails.value ? getAgentLabel(agentOptions, deviceDetails.value as string | number) : ''}
        //     </List.Item>
        //     <Picker.Cascader
        //       options={agentOptions}
        //       visible={pickerVisible}
        //       onClose={() => {
        //         setPickerVisible(false)
        //       }}
        //       confirmText="Selecteaza"
        //       cancelText="Anuleaza"
        //       onConfirm={value => editDetails(deviceDetails.key, value[0] as string)}
        //     />
        //   </div>
        // }
        else {
          return <List.Item prefix={deviceDetails.label} key={deviceDetails.key} style={{ paddingRight: 6 }}>
            <Input
              type={deviceDetails.type}
              inputMode={deviceDetails.type === 'number' ? 'decimal' : 'text'}
              pattern={deviceDetails.type === 'number' ? "\d*" : undefined}
              enterKeyHint=""
              onChange={value => editDetails(deviceDetails.key, value)}
              clearable
              value={deviceDetails.value ? deviceDetails.value as string | number : ''}
              readOnly={deviceDetails.readOnly}
              style={{ opacity: deviceDetails.readOnly ? 0.7 : 1 }}
              onFocus={handleFocus}
              onBlur={(event) => handleBlur(event, deviceDetails.key, deviceDetails.value as string | number)}
            />
          </List.Item>
        }
      })
      }
    </List>
  </div >);
}

// function getAgentLabel(agents, id: string | number) {
//   let label = ''
//   for (let i = 0; i < agents.length; i++) {
//     if (agents[i].value === id) {
//       label = agents[i].label
//     }
//   }
//   return label
// }

const alert = (content: string, target, title = 'Eroare validare'): void => {
  Dialog.alert({
    title,
    content,
    confirmText: 'Modifica',
    closeOnMaskClick: true,
    onConfirm: () => {
      target.focus()
    }
  })
}