import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../../app/store'

// Define a type for the slice state
interface NavState {
  title: string
  showBackButton: boolean
}

// Define the initial state using that type
const initialState: NavState = {
  title: 'Autentificare',
  showBackButton: false
}

export const navSlice = createSlice({
  name: 'navbar',
  initialState,
  reducers: {
    editTitle: (state, action: PayloadAction<string>) => {
      if (state.title !== action.payload) {
        state.title = action.payload
      }
    },
    editShowBackButton: (state, action: PayloadAction<boolean>) => {
      if (state.showBackButton !== action.payload) {
        state.showBackButton = action.payload
      }
    }
  }
})

export const { editTitle, editShowBackButton } = navSlice.actions

export const selectTitle = (state: RootState) : string => state.navbar.title

export const selectShowBackButton = (state: RootState) : boolean => state.navbar.showBackButton

export default navSlice.reducer