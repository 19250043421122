// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import React, { ReactElement, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../app/hooks'
import { editUser, selectUser } from '../features/user/userSlice'
import { Redirect } from 'react-router-dom';
import { Input, List, Button, Grid, Toast } from 'antd-mobile-alpha';
import { editShowBackButton, editTitle } from '../features/navbar/navSlice';
import axios from 'axios'
import { LoginOutlined } from '@ant-design/icons';
import { API_URL } from '../constants';

export const Login = (): ReactElement => {
  const dispatch = useAppDispatch()
  const [loading, setLoading] = useState(false);
  const [code, setCode] = useState('')
  const [password, setPassword] = useState('')
  let codeError = !code;

  const storedUser = useAppSelector(selectUser);
  const isAuthenticated = storedUser && storedUser.token && storedUser.token.length;

  useEffect(() => {
    dispatch(editTitle('FlexVend'))
    dispatch(editShowBackButton(false))
  })

  const onErrorClick = () => {
    if (codeError) {
      Toast.show({ content: 'Codul de agent este obligatoriu', position: 'top', maskClickable: true });
    }
  }
  const onChangePassword = (value: string) => {
    setPassword(value);
  }
  const onChangeCode = (value: string) => {
    if (code.replace(/\s/g, '').length < 1) {
      codeError = true
    } else {
      codeError = false
    }
    setCode(value);
  }

  const handleLogin = () => {
    onErrorClick();
    if (codeError) {
      return;
    }
    setLoading(true);
    axios.post(`${API_URL}/api/login`, { code, password })
      .then(response => {
        setLoading(false);
        dispatch(editUser(response.data))
      })
      .catch(error => {
        setLoading(false);
        let message = 'Unknown error';
        if (error.response && error.response.data && error.response.data.message) {
          message = error.response.data.message
        }
        Toast.show({ content: message, position: 'top', maskClickable: true });
      })
  }

  return (isAuthenticated ?
    <Redirect to="/devices" />
    :
    < Grid columns={1} style={{ justifyItems: 'center' }}>
      < Grid.Item style={{ padding: 16 }} >
        <br /><br />
        <List style={{
          '--prefix-width': '6em',
        }}>
          < List.Item prefix='Cod Agent' >
            < Input clearable placeholder='12345' enterKeyHint='' required onChange={onChangeCode} />
          </ List.Item >
          < List.Item prefix='Parola' >
            < Input clearable placeholder='***' type='password' enterKeyHint='' required onChange={onChangePassword} />
          </ List.Item >
        </List>
        <br /><br />
        <Button loading={loading} size="large" color="primary" block onClick={handleLogin}><LoginOutlined /> Autentificare</Button>
      </ Grid.Item >
    </ Grid >
  )
}
