// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import React, { ReactElement, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { selectEditedDevice, selectVisit, updateEditedDevice, updateVisit } from '../features/devices/devicesSlice';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { Button, Dialog, Grid, Loading, Toast } from 'antd-mobile-alpha';
import { Footer } from 'antd/lib/layout/layout';
import DeviceTab from './tabs/DeviceTab'
import { Tabs } from 'antd-mobile';
import SelectionsTab from './tabs/SelectionsTab';
import axios from 'axios';
import { API_URL } from '../constants';
import { editShowBackButton, editTitle } from '../features/navbar/navSlice';
import { selectCurrentAgent } from '../features/application/applicationSlice';

type DeviceParams = {
  id: string
}

type DeviceProps = {
  abandonChanges: () => void
}

const tabs = [
  { title: 'Aparat' },
  { title: 'Selectii' },
];

export default function DeviceLayout(props: DeviceProps): ReactElement {
  const device = useAppSelector(selectEditedDevice)
  const visit = useAppSelector(selectVisit)
  const currentAgent = useAppSelector(selectCurrentAgent)
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const dispatch = useAppDispatch()
  const history = useHistory()
  const { id } = useParams<DeviceParams>();
  loadDevice(id, setLoading, device)
  if (loading) {
    return <div style={{ textAlign: 'center', paddingTop: 16 }}>
      <Loading color="primary" />
    </div>
  }
  if (!device) {
    return <div />
  }
  const { details, headers, selections } = device;

  const onUpdate = (value: EditedDevice) => {
    dispatch(updateEditedDevice(JSON.parse(JSON.stringify(value))))
  }

  function saveChanges(setSaving: (value: boolean) => void, device: EditedDevice) {
    setSaving(true);
    dispatch(editShowBackButton(false))
    let idAparat: string | number = -1;
    device.details.forEach(item => {
      if (item.key === 'idAparat') {
        idAparat = item.value as string | number;
      }
    })
    const body = visit ? { ...device, visitId: visit.id } : { ...device };
    axios.put(`${API_URL}/api/devices/${idAparat}`, currentAgent ? { ...body, agent: currentAgent.id } : body)
      .then(() => {
        setSaving(false);
        dispatch(updateEditedDevice(null));
        dispatch(updateVisit(null));
        history.push('/devices');
        Toast.show({ content: 'Modificari salvate cu success!', position: 'top', maskClickable: true });
      })
      .catch(error => {
        setSaving(false);
        let message = 'Unknown error';
        if (error.response && error.response.data && error.response.data.message) {
          message = error.response.data.message
        }
        Toast.show({ content: message, position: 'top', maskClickable: true });
        dispatch(editShowBackButton(true))
      })
  }

  function onUpdateIndex() {
    if (!device || !device.selections || !device.details) {
      return;
    }
    const details = JSON.parse(JSON.stringify(device.details))
    let indexCurentTotal = 0;
    let indexVechiTotal = 0;
    let indexVechiDetails = 0;
    for (let i = 0; i < device.selections.length; i++) {
      if (device.selections[i]['indexCurent']) {
        indexCurentTotal += parseFloat(device.selections[i]['indexCurent'])
      }
      if (device.selections[i]['indexVechi']) {
        indexVechiTotal += parseFloat(device.selections[i]['indexVechi'])
      }
    }
    for (let i = 0; i < details.length; i++) {
      if (details[i] && details[i]['key'] === 'indexVechi') {
        if (details[i]['value']) {
          indexVechiDetails = parseFloat(details[i]['value']);
        }
        break;
      }
    }
    for (let i = 0; i < details.length; i++) {
      if (details[i] && details[i]['key'] === 'indexNou') {
        details[i].value = (indexCurentTotal - indexVechiTotal + indexVechiDetails).toString()
        break;
      }
    }
    onUpdate({ ...device, details })
  }

  function loadDevice(id: string, setLoading: (value: boolean) => void, device: EditedDevice | null) {
    const dispatch = useAppDispatch()
    useEffect(() => {
      if (device === null) {
        setLoading(true);
        dispatch(updateVisit(null))
        axios.get(`${API_URL}/api/devices/${id}${currentAgent ? '?agent=' + currentAgent.id : ''}`)
          .then(response => {
            const data = { ...response.data };
            if (data.visit) {
              Dialog.confirm({
                title: 'Vizita existenta',
                content: `Doresti sa modifici vizita din ${data.visit.date} sau sa adaugi una noua?`,
                confirmText: 'Vizita noua',
                cancelText: 'Modifica',
                onCancel: () => {
                  setLoading(true);
                  loadVisit(id, data.visit, setLoading, dispatch);
                }
              })
            }
            dispatch(updateEditedDevice(data))
          })
          .catch(error => {
            let message = 'Unknown error';
            if (error.response && error.response.data && error.response.data.message) {
              message = error.response.data.message
            }
            Toast.show({ content: message, position: 'top', maskClickable: true });
          })
          .finally(() => {
            dispatch(editShowBackButton(true))
            setLoading(false);
          })
      } else {
        device.details.forEach(item => {
          if (item.key === 'locatie') {
            dispatch(editTitle(item.value as string))
          }
        })
        dispatch(editShowBackButton(true))
      }
    }, []);
  }

  function loadVisit(id: string, visit: Visit, setLoading: (value: boolean) => void, dispatch) {
    axios.get(`${API_URL}/api/devices/${id}?visitId=${visit.id}${currentAgent ? '&agent=' + currentAgent.id : ''}`)
      .then(response => {
        dispatch(updateEditedDevice(response.data));
        dispatch(updateVisit(visit));
      })
      .catch(error => {
        let message = 'Unknown error';
        if (error.response && error.response.data && error.response.data.message) {
          message = error.response.data.message
        }
        Toast.show({ content: message, position: 'top', maskClickable: true });
      })
      .finally(() => {
        setLoading(false);
      })
  }

  return (<div>
    <div style={{ paddingBottom: 43 }}>
      <Tabs tabs={tabs} initialPage={0} useOnPan={false} swipeable={false}>
        <div style={{ height: '100% !important' }}>
          <DeviceTab details={[...details]} updateDetails={details => onUpdate({ ...device, details })} />
        </div>
        <div style={{ height: '100% !important' }}>
          <SelectionsTab headers={headers} selections={selections} updateSelections={selections => onUpdate({ ...device, selections })} indexUpdated={onUpdateIndex} />
        </div>
      </Tabs>
    </div>
    <Footer style={{ textAlign: 'center', position: 'fixed', zIndex: 1, width: '100%', bottom: 0, padding: 0 }}>
      < Grid columns={2} gap={8} style={{ padding: 4 }}>
        < Grid.Item >
          <Button block onClick={props.abandonChanges}>Abandon</Button>
        </ Grid.Item >
        < Grid.Item >
          <Button block color="primary" onClick={() => saveChanges(setSaving, device)} loading={saving}>Vizita OK</Button>
        </ Grid.Item >
      </ Grid >
    </Footer>

  </div>);
}
